<template>
  <div class="d-flex flex-column gap-3 mt-4">
    <div v-if="template.indicators.length == 0"> <b-icon-exclamation-triangle-fill /> Nenhum Indicador cadastrado para
      esse sub-módulo</div>
    <b-card v-for="(indicator, index) in template.indicators" :key="index">
      <section v-if="indicator.indicator" class="d-md-flex align-items-center justify-content-between ">
        <span>
          <strong>
            {{ indicator.indicator.name }}
          </strong>
          ({{ getTypeValidation(indicator.type) }})
        </span>
        <span v-if="indicator.score">
          Nota: <strong>{{ indicator.score.toFixed(1) }}</strong>
        </span>
      </section>

      <section class="d-md-flex align-items-center mt-2 mb-4 justify-content-between w-100 gap-4">
        <div>
          <span class="mr-2">Valor coletado: </span>
          <span>
            {{ formatValue(indicator.collected_value, indicator.type) }}
          </span>
        </div>
        <div>
          <span class="mr-2">Validação: </span>
          <span>
            {{ indicator.type === "manual" ? "Manual" : "Sistêmica" }}
          </span>
        </div>
      </section>
      <range-validation class="mt-2" :note="indicator.score" :tracks="indicator.ranges" :type="indicator.type"
        :indicator_id="indicator.indicator.id" @update_indicators="$emit('update_indicators')" />
    </b-card>
  </div>
</template>

<script>
import RangeValidation from "./RangeValidation";
import { mapGetters } from "vuex";

export default {
  components: {
    RangeValidation,
  },
  props: {
    template: Object,
  },
  methods: {
    formatValue(value, type) {
      if (value < 0) {
        return "Valor não coletado";
      }

      switch (type) {
        case "percentage":
          return `${(value * 100).toFixed(2)}%`;
        case "money":
          return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        case "value":
          return String(value.toFixed(2)).replace(",", ".");
        case "yes_no":
          return value;
        case "include":
          return value;
        case "manual":
          return value;
      }
    },
  },
  computed: {
    ...mapGetters(["getTypeValidation"]),
  },
};
</script>
