<template>
  <div>
    <div class="tracks-container">
      <div class="tracks"></div>
      <div @click="handleSelectScore(track)" :id="`track-${track}`" class="circle" :class="[
        Number(selectedNote) === Number(track.value) ? 'active' : '',
        // type === 'manual' && service_scope.status === 0 ? 'can-select' : '',
        type === 'manual' ? 'can-select' : '',

      ]" v-for="(track, index) in tracksArr" :key="index">
        <span class="ball">
          {{ String(track.value).replace(".0", "") }}
        </span>

        <div class="inputs mx-1 p-1" :class="isLastItem(index) || isFirstItem(index) ? 'mt-2' : 'mt-2'">
          <section v-if="type === 'yes_no' || type === 'include'">
            <span class="d-block text-center">
              {{ track.max === 1 ? "Não" : "Sim" }}
            </span>
          </section>
          <section v-else>
            <b-icon-arrow-down v-if="isLastItem(index)" class="d-flex justify-content-center w-100  mb-1 value" />
            <section class="text-center">
              <small v-if="isLastItem(index)" class="line-height-1 value">
                &gt;
              </small>
              <span class="d-block value">
                {{ formatValue(getNumberFixed(track.min, index)) }}
              </span>
            </section>

            <section class="text-center">
              <b-icon-arrow-down v-if="!isLastItem(index)" class="d-flex justify-content-center w-100  mb-1 value" />
              <section v-if="isFirstItem(index)">
                <span class="line-height-1 value">&lt;</span>
                <span class="d-block">
                  {{ formatValue(getMaxNumberFixed(track.max)) }}
                </span>
              </section>
              <span class="d-block value" v-if="!isFirstItem(index) && !isLastItem(index)">
                {{ formatValue(getMaxNumberFixed(track.max)) }}
              </span>
            </section>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["service_scope"]),
  },

  props: {
    tracks: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
    },

    indicator_id: {
      type: [String, Number],
    },

    note: {
      type: [Number, null],
    },
  },
  data() {
    return {
      service_id: "",
      ranking_id: "",
      provider_id: "",
      indicatoModule: "",
      indicatoSubModule: "",
      tracksArr: [],
      selectedNote: 0,
    };
  },
  methods: {
    ...mapActions([
      "set_service_validation_admin_indicator",
      "get_service_validation_admin_history",
    ]),

    formatValue(value) {
      const val = this.$options.filters.number(value);

      switch (this.type) {
        case "percentage":
          return `${(val * 100).toFixed(2)}%`;
        case "money":
          return val.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        default:
          return val;
      }
    },

    setValidation(score) {
      this.set_service_validation_admin_indicator({
        service_id: this.service_id,
        provider_id: this.provider_id,
        module: this.indicatoModule,
        sub_module: this.indicatoSubModule,
        score,
        indicator_id: this.indicator_id,
      }).then(() => {
        this.$emit("update_indicators");
        this.get_service_validation_admin_history({
          ranking_id: this.ranking_id,
        });
      });
    },

    getMaxNumberFixed(num) {
      if (!num) {
        return;
      }

      return Number(num).toFixed(2);
    },

    getNumberFixed(num, index) {
      if (!num) {
        return;
      }
      if (index === 0) {
        return Number(num).toFixed(2);
      }

      return Number(num + 0.01).toFixed(2);
    },

    handleSelectScore(track) {
      // if (this.service_scope.status !== 0) {
      //   return;
      // }

      if (this.type === "manual") {
        this.setValidation(track.value);
        this.selectedNote = +track.value;
      }
    },

    isLastItem(index) {
      return index === this.tracksArr.length - 1;
    },

    isFirstItem(index) {
      return index === 0;
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.ranking_id = this.$route.params.ranking_id;
    this.provider_id = this.$route.params.provider_id;
    this.tracksArr = this.tracks;
  },
  watch: {
    tracks: {
      handler(tracks) {
        this.tracksArr = tracks;
      },
      deep: true,
    },
    note: {
      handler(value) {
        this.selectedNote = value;
      },
      immediate: true,
    },
    $route: {
      handler(route) {
        this.indicatoModule = route.query.module;
        this.indicatoSubModule = route.query.sub;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tracks-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.tracks-container::before {
  content: "";
  /* must be added when using the ::before pseudoselector */
  background-color: $border-color;
  position: absolute;
  top: 16px;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  z-index: 0;
  transition: 0.4s ease;
  width: 100%;
}

.tracks {
  background-color: $border-color;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: 0;
  transition: 0.4s ease;
}

.circle {
  z-index: 0;
  border-radius: $radius;
  border: 1px solid var(--line-border-empty);
  cursor: not-allowed;
  user-select: none;
  padding: 4px 0;
  top: -4px;
  position: relative;
  border: 1px solid transparent;

  &.can-select {
    cursor: pointer;

    &:hover {
      border-color: $border-color;
    }
  }

  &.active {
    background: lighten($primary, 34%);
    border-color: $primary;

    &:hover {
      border-color: $primary;
    }

    .ball {
      background: $primary;
      color: $white;
    }

    .value {
      color: $primary;
    }
  }

  .ball {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bg;
    color: $text-color;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 auto;
  }
}
</style>
